@media screen and (min-width: 1060px), (min-height: 800px) {
    .results-body {
        display: flex;
        flex-direction: column;
        height: 100vh;
        overflow: hidden;
    }

    .results-panel {
        margin: 0 40px;
        overflow-y: auto;
    }

    .header {
        flex-shrink: 0;
    }

    .pagination {
        flex-shrink: 0;
    }
}

.header {
    z-index: 2;
}

.tabpanel {
    height: -webkit-fill-available;
}

svg {
    z-index: 1;
}

.search-params-bar-grid {
    display: grid;
    text-align: center;
}

.checkbox-filters {
    grid-area: checkboxes;
}

.age-slider {
    grid-area: ageslider;
}

.phase-slider {
    grid-area: phaseslider;
}

.filter {
    grid-area: filter;
}

@media screen and (min-width: 1060px) {
    .search-params-bar-grid {
        grid-template-columns: 28% 20% 40% 12%;
        grid-template-areas: 'checkboxes ageslider phaseslider filter';
    }

    .age-slider, .phase-slider {
        padding: 0 50px;
    }

    .filter button {
        float: right;
        margin-right: 10px;
    }
}

@media screen and (max-width: 1060px) {
    .search-params-bar-grid {
        grid-template-columns: 100%;
        grid-template-areas: 'checkboxes'
         'ageslider'
         'phaseslider'
         'filter';
    }

    .age-slider, .phase-slider {
        padding: 20px 50px 0 50px;
    }

    .filter {
        margin-top: 20px;
        padding: 6px 24px 6px 24px;
    }
}

.MuiSlider-mark {
    text-overflow: ellipsis;
}

.pagination, .results-count {
    display: flex;
    justify-content: left;
    align-items: center
}

.pagination {
    margin: 10px 0 20px 0;
    grid-template-areas: 'pagination';
}

.results-count {
    margin: 0 0 10px 80px;
    grid-template-areas: 'rescount';
    font-size: 14px !important;
    color: #505050;
}

.pagination-container {
    display: grid;
    grid-template-columns: 6% 43% 53%;
    grid-template-areas: 'rescount pagination';
}

@media screen and (max-width: 700px) {
    .pagination-container {
        grid-template-columns: 100%;
        grid-template-areas: 'rescount'
            'pagination';
    }

    .pagination, .results-count {
        justify-content: center;
        margin-left: 0;
    }
}

.autocomplete-text {
    display: inline-block;
    width: calc(100% - 80px);
}

.autocomplete-buttons {
    display: block;
    float: right;
    width: 80px;
    margin-top: 10px;
}

.autocomplete-container {
    display: inline-block;
    width: 100%;
}

.autocomplete-container .MuiIconButton-root {
    height: fit-content;
}

.MuiBox-root {
    height: 100vh;
}

.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
    padding: 8px 0 !important;
}

.MuiAutocomplete-tag {
    background-color: #f7f7f7 !important;
    border-radius: 2px !important;
}

.disease-colour {
    background-color: #edf3fd !important;
}

.drug-colour {
    /*background-color: #fff0fa !important;*/
    background-color: lightskyblue !important;
}

.gene-colour {
    background-color: #ffffe4 !important;
}

.mutation-colour {
    background-color: #e9ffe9 !important;
}

.species-colour {
    /*background-color: #fbf2ff !important;*/
    background-color: lightblue !important;
    margin-left: 10px;
}

.no-data {
    display: flex; 
    justify-content: center;
    align-items: center;
    height: inherit;
    color: grey;
}

.download-cases-btn  {
    margin: 20px 70px !important;
    float: right;
}

.below-search-bar {
    text-align: center;
    height: 30px;
    margin-top: -10px;
}

.search-results-returned {
    text-align: center;
    color: #505050;
    font-size: 14px !important;
}

.loading-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.saved-query {
    flex-grow: 1; 
    height: 40px;
    line-height: 40px;
    background-color: #f5f5f5;
    margin: 20px 20px -10px 10px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.95rem;
    padding-left: 10px;
}
