.mui-pop-root {
    width: 700px;
    height: 600px;
}

.mui-paper {
    border-radius: 10px !important;
    background-color: aliceblue !important;
}

.mui-typo-outer-root {
    padding: 18px 18px 6px 18px;
}

.mui-typo-root {
    margin-bottom: 12px !important;
}

.mui-icon-button {
    &:hover {
        background-color: transparent !important;
    }
}

.close {
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    color: lightgrey !important;
    font-size: 2 !important;
}

.close svg {
    font-size: 20;
}

.MuiTypography-root .Mui-disabled {
    opacity: inherit !important;
}

.results-reference-link {
    color: -webkit-link;
}
