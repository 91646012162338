.graph-svg {
    height: 100%;
    float: left;
}

.graph-tool-bar {
    display: inline-flex;
    justify-content: end;
    grid-area: graphtoolbar;
}

.colour-keys {
    grid-area: colourkeys;
    text-align: right;
    margin: 0 20px 0 20px;
}

.colour-keys-label {
    padding: 3px 6px 6px 6px;
}

.colour-keys-label .MuiFormControlLabel-root {
    margin: 0px !important;
}

.graph-tool-bar-container {
    width: 100%;
    height: 80px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: 'colourkeys graphtoolbar';
}

#nodeGroup {
    width:  100%;
    height: 100%;
}

.knowledge-graph-container {
    width: 100%;
    height: 100%;
    position: relative;
}
  
.knowledge-graph-container svg {
    display: block;
}

.node text {
    pointer-events: none;
    font: 12px sans-serif;
}

.context-menu {
    stroke: #999;
    fill: #ffffff;
}

.context-menu text {
    font: 18px sans-serif;
    stroke-width:0px;
    stroke: black;
    fill: black;
}

#menu-entry {
    cursor: pointer;
}

#menu-entry text {
    font-size: 14px;
    stroke: #00557d;
    fill: #00557d;
}

#menu-zoom-icon {
    font-size: 200px;
}

.node-rect {
    padding: 10px;
}

.rect-disease {
    fill: #edf3fd;
}

.rect-drug {
    fill: lightskyblue;
}

.rect-gene {
    fill: #ffffe4;
}

.rect-mutation {
    fill:#e9ffe9;
}

.rect-species {
    fill: lightblue;
}

.rect-central-node {
    fill: #f55c7a;
}

.menu-entry a:link, .menu-entry a:visited, .context-title, .node-rect {
    cursor: pointer;
}

.menu-entry a text {
    fill: blue;
    stroke: blue;
}

.menu-entry a text {
    text-decoration: underline;
}

.article-type {
    text-decoration: underline;
}

.bullet-point {
    align-items: center;
    justify-content: center;
}

.bullet-point circle {
    stroke: #00557d;
    fill: #00557d;
    stroke-width: 2;
}

.graph-tool {
    margin: 10px 10px 10px 6px;
    width: 190px;
}

#central-node-links {
    width: 150px;
}

.zoom-btn {
    margin: 0 40px 0 -10px !important;
    padding: unset !important;
    width: 50px;
}

.zoom-btn:hover {
    background-color: unset !important;
}

.nodes-displayed {
    padding: 10px;
    margin-right: 20px !important;
    color: #505050;
    margin-top: -12px !important;
    font-size: 14px !important;
}

.option-group-disease, .option-group-disease:hover, .autocomplete-input-disease input {
    background-color: #edf3fd !important;
}

.option-group-drug, .option-group-drug:hover, .autocomplete-input-drug input {
    background-color: lightskyblue !important;
}

.option-group-gene, .option-group-gene:hover, .autocomplete-input-gene input {
    background-color: #ffffe4 !important;
}

.option-group-mutation, .option-group-mutation:hover, .autocomplete-input-mutation input {
    background-color:#e9ffe9 !important;
}

.option-group-species, .option-group-species:hover, .autocomplete-input-species input {
    background-color: lightblue !important;
}
