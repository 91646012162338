.drawer {
    position: fixed;
    top: -100%;
    left: 0;
    height: 100%;
    background-color: #fff;
    transition: top 0.3s ease-in-out;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.drawer.open {
    top: 0;
}

.toggle-button {
    display: block;
    margin: 10px auto;
}

.drawer-content {
    padding: 20px;
    opacity: 1;
}

.MuiDrawer-paper {
    width: min-content;
}

.MuiList-root .MuiPaper-root {
    width: -webkit-fill-available ;
}

.case-input .MuiInputBase-input {
    padding: 16.5px 14px !important;
}

.new-case-card .MuiCardContent-root {
    padding: 0 16px 10px 16px !important;
}

.create-new-case-header {
    .MuiCardHeader-title, .MuiCardHeader-action {
        padding-bottom: 10px;
    }
}

.new-case-card {
    min-height: 320px;
}

.select-share-form-control {
    margin: 20px !important;
    min-width: 120px;
    max-width: 300px;
}

.cases-group-title {
    margin-left: 30px !important;
    font-size: small !important;
}

.case-switcher-form-group {
    width: -webkit-fill-available;
}

#survey-dialog-title {
    margin: 20px !important;
}

.survey-questions-quantitative {
    margin: 0 40px 0 40px;
    display: grid;
    grid-template-columns: 80% 20%;
}

.survey-questions-qualitative {
    margin: 0 40px 0 40px;
    display: grid;
    grid-template-columns: 100%;
}

.survey-questions-likert {
    margin: 0 80px 0 80px;
    display: grid;
    grid-template-columns: 100%;
}

.survey-textfield {
    width: 100px;
    margin-top: 8px !important;
    justify-self: end;
}

.survey-question {
    margin: 24px 0 24px 0 !important;
}

.survey-question-likert {
    margin: 10px 0 10px -30px !important;
}

.oscar-survey {
    text-align: center;
    margin: 20px 10px 10px 0;
}

.mark-label {
    white-space: pre-wrap !important;
    width: 60px;
}

.survey-slider {
    margin-bottom: 100px !important;
}
