.results {
    margin-top: 20px;
}

.pubmed-header, .trial-header {
    display: grid;
}

.result-type {
    grid-area: resulttype;
    margin: 10px;
    padding: 6px 16px;
    height: fit-content;
}

.pubmed-type {
    background-color: #eddee2;
    width: 50px;
}

.trial-type {
    background-color: #ffeecc;
    width: 32px;
}

.trial-type-title {
    grid-area: trialtypetitle;
    display: grid;
}

.pubmed-type-title {
    grid-area: pubmedtypetitle;
    display: grid;
}

.pubmed-journal {
    grid-area: submityear;
    padding: 5px 0px 15px 0px;
}

.result-title {
    grid-area: resulttitle;
    margin: 10px 10px 10px 0;
    display: grid;
}

a.result-title {
    font-weight: 600;
    font-size: 17px;
}

.reference-icon {
    grid-area: referenceicon;
    margin-top: -12px;
}

.title-title {
    grid-area: titletitle;
}

.submit-year {
    grid-area: submityear;
    padding-top: 6px;
}

.pubmed-switch {
    grid-area: pubmedswitch;
}

.trial-switch {
    grid-area: trialswitch;
}

.pubmed-switch, .trial-switch {
    display: flex;
    justify-content: flex-end;
}

.relevance-buttons {
    margin-top: 0;
    padding-top: 0;
    float: right;
    text-align: right;
}

.result-abstract {
    grid-area: resultabstract;
    margin: 0 40px;
    border-left: solid 2px;
    padding-left: 20px;
}

.result-abstract p {
    margin: 0;
}

.result-annot-age-eligib {
    grid-area: resultannotageeligib;
    display: grid;
    border-left: solid 2px;
    padding-top: 10px;
    margin: 0 40px;
}

.result-annotations {
    grid-area: resultannot;
    margin: 0 20px 10px 20px;
}

.result-age-eligib {
    grid-area: resultageeligib;
}

.annotation-label {
    width: fit-content;
    padding: 3px 4px 4px 4px;
    margin: 5px 5px;
    display: inline-block;
    font-size: 14px;
}

.phase {
    grid-area: phase;
    margin: 0px 40px;
    padding-bottom: 8px;
    border-left: solid 2px;
}

.phase-labels {
    margin-left: 24px;
    width: fit-content;
}

.phase-labels label {
    margin-right: 10px;
    padding: 2px 10px 4px 10px;
    width: fit-content;
    border: solid 2px;
    height: 18px;
}

.phase .publication-type {
    color: #59a996;
}

@media screen and (min-width: 950px), (max-width: 1400px) {
    .pubmed-header {
        grid-template-columns: 80% 20%;
        grid-template-areas: 'pubmedtypetitle pubmedswitch';
    }

    .trial-header {
        grid-template-columns: 80% 20%;
        grid-template-areas: 'trialtypetitle trialswitch';
    }

    .result-annot-age-eligib {
        grid-template-columns: 80% 20%;
        grid-template-areas: 'resultannot resultageeligib';
    }
}

@media screen and (min-width: 1400px) {
    .result-annot-age-eligib {
        grid-template-columns: 90% 10%;
        grid-template-areas: 'resultannot resultageeligib';
    }
}

@media screen and (max-width: 950px) {
    .pubmed-header {
        grid-template-columns: 80% 20%;
        grid-template-areas: 'pubmedtypetitle pubmedswitch';
    }

    .trial-header {
        grid-template-columns: 80% 20%;
        grid-template-areas: 'trialtypetitle trialswitch';
    }
}

@media screen and (max-width: 950px), (min-width: 700px) {
    .result-annot-age-eligib {
        grid-template-columns: 84% 16%;
        grid-template-areas: 'resultannot resultageeligib';
    }
}

@media screen and (max-width: 760px) {
    .result-annot-age-eligib {
        grid-template-columns: 100%;
        grid-template-areas: 'resultageeligib'
        'resultannot';
    }

    .result-age-eligib {
        max-width: 150px;
        margin: 10px 30px;
    }

    .result-abstract, .result-annot-age-eligib, .phase {
        margin: 0 20px;
    }
}

.trial-type-title {
    grid-template-columns: 90px 80%;
    grid-template-areas: 'resulttype resulttitle';
}

.pubmed-type-title {
    grid-template-columns: 120px 80%;
    grid-template-areas: 'resulttype resulttitle';
}

.title-references {
    grid-template-columns: min-content auto;
    grid-template-areas: 'referenceicon titletitle'
        '. submityear';
}

.result-abstract .MuiButtonBase-root {
    padding: 0;
}

.aus-svg {
    margin: -6px 0 0 8px;
}
